define("ember-intl/-private/formatters/-base", ["exports", "@ember/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); } /**
                                                                                                                                                                                                                                                                                                                                                                                             * Copyright 2015, Yahoo! Inc.
                                                                                                                                                                                                                                                                                                                                                                                             * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
                                                                                                                                                                                                                                                                                                                                                                                             */
  // eslint-disable-next-line @typescript-eslint/ban-types
  const EMPTY_OBJECT = Object.create(null);
  /**
   * @private
   * @hide
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  class FormatterBase {
    constructor(config) {
      _defineProperty(this, "config", void 0);
      _defineProperty(this, "readFormatConfig", void 0);
      this.config = config;

      // NOTE: a fn since we lazily grab the formatter from the config
      // as it can change at runtime by calling intl.set('formats', {...});
      this.readFormatConfig = config.readFormatConfig;
    }
    get options() {
      return [];
    }

    /**
     * Filters out all of the whitelisted formatter options
     *
     * @method filterKnownOptions
     * @param {Object} Options object
     * @return {Object} Options object containing just whitelisted options
     * @private
     */
    filterKnownOptions(options) {
      if (!options) {
        return EMPTY_OBJECT;
      }
      const found = {};
      for (const key in options) {
        if (this.options.includes(key)) {
          found[key] = options[key];
        }
      }
      return found;
    }
    readOptions(formatOptions) {
      let formatterOptions = this.filterKnownOptions(formatOptions);
      if (formatOptions && 'format' in formatOptions) {
        const namedFormatsOptions = this.getNamedFormat(formatOptions.format);
        formatterOptions = _objectSpread(_objectSpread({}, namedFormatsOptions), formatterOptions);
      }
      return formatterOptions;
    }
    validateFormatterOptions(locale, _formatterOptions) {
      if (!locale) {
        // TODO: config.onError instead?
        (false && (0, _debug.warn)("[ember-intl] no locale has been set!  See: https://ember-intl.github.io/ember-intl/docs/quickstart#4-configure-ember-intl", false, {
          id: 'ember-intl-no-locale-set'
        }));
      }
    }
    getNamedFormat(key) {
      const formats = this.readFormatConfig();
      const namedFormatsForType = formats[this.constructor.type];
      if (namedFormatsForType && namedFormatsForType[key]) {
        return namedFormatsForType[key];
      }
    }
  }
  _exports.default = FormatterBase;
  _defineProperty(FormatterBase, "type", void 0);
});